.safty-section {
    .section-title {
        text-align: center;
        max-width: 600px;
        margin: 0 auto 50px;

        h2 {
            max-width: inherit;
        }
    }

    .safety-video {
        max-width: 750px;
        margin: auto;
    }
}