@import '../../sass/colors.scss';
@import '../../sass/fonts.scss';

.icon-box {
    width: 300px;
    height: 250px;
    line-height: 0px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    text-align: left;

    &::before {
        position: absolute;
        content: '';
        width: 100px;
        height: 100px;
        top: -10px;
        left: -10px;
        z-index: -1;
        opacity: .3;
        border-radius: 20px;
    }

    img {
        max-width: 250px;
    }
}


// Mobile Responsive
@media (max-width: 575px) {
    .icon-box {
        width: 200px;
        height: 180px;
        line-height: 40px;
        border-radius: 8px;

        &::before {
            width: 200px;
            height: 180px;
            border-radius: 10px;
        }

        img {
            max-width: 200px;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .icon-box {
        width: 200px;
        height: 180px;
        line-height: 40px;
        border-radius: 8px;

        &::before {
            width: 200px;
            height: 180px;
            border-radius: 10px;
        }

        img {
            max-width: 200px;
        }
    }
}