@import '../../sass/colors.scss';

.service-boxnew {
    border: 4px solid #1819451a;
    padding: 80px;
    border-radius: 90px;
    margin-bottom: 30px;
   


   
}

.service-section {
    .service-title-text {
        color: $color-black ;
        padding-top: 50px;
        max-width: 600px;
        margin: 0 0 0 auto;
        font-weight: 500;
    }

    .services-link {
        margin: 50px 0 30px;

        a {
            color: white;
            text-decoration: none;
            font-weight: 700;
            background: #C29C4B;
            padding: 15px 25px;
            display: inline-block;
            border-radius: 15px;

            svg {
                margin-left: 10px;
                font-size: 20px;
                top: -2px;
                position: relative;
            }
        }
    }
}

//Mobile Responsive
@media (max-width: 575px){
    .service-section {
        .service-title-text {
            padding-top: 0;
            margin-bottom: 10px;
        }
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .service-section {
        .service-title-text {
            padding-top: 0;
            margin-bottom: 30px;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .service-section {
        .service-title-text {
            padding-top: 0;
            margin-bottom: 30px;
        }
    }
}

.black {
    color: black !important;
}

@import '../../sass/colors.scss';

.symptoms-section {
    margin-top: 150px;
    background: #D9D9D9;

    .section-title {
        text-align: center;

        p {
            max-width: 700px;
            margin: auto;
        }
    }

}

// Mobile Responsive
@media (max-width:575px){
    .symptoms-section {
        margin-top: 50px;
    }
}

@media (min-width:576px) and (max-width: 767px){
    .symptoms-section {
        margin-top: 50px;
    }
}