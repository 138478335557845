@import'../../sass//colors.scss';

.icon-list {
    display: flex;
    align-items: top;

    .icon-area {
        max-width: 120px;
    }

    .icon-list-text {
        max-width: 500px;
        

        h3 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            margin-bottom: 0;
            font-weight: 500;
            color: $color-black;
        }
    }
}

// Mobile Responsive
@media (max-width:575px){
    .icon-area {
        .icon-list {
            margin-bottom: 25px;
        }
    }
}