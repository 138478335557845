.service-box {
    border: 1px solid #1819451a;
    padding: 15px;
    border-radius: 32px;
    text-align: center;
    margin-bottom: 30px;
    min-height:480px;


    .icon-area {
        padding: 0px;
        border-radius: 30px;
        margin-bottom: 10px;
        min-height: 200px;
        text-align: center;
        

        .icon-box {
            margin: auto;
            text-align: center;
        }
    }

    .service-text {
        h3 {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;

            a {
                text-decoration: none;
                color: #181945;
            }
        }

        p {
            font-size: 14px;
            color: #181945;
        }
    }
}



// Small Device Responsive
@media (min-width: 576px) and (max-width: 767px) {
    .service-box {
        .icon-area {
            padding: 10px;
        }
    }
}

// Medium Device Responsive
@media (min-width: 768px) and (max-width: 991px) {
    .service-box {
        .icon-area {
            padding: 10px;
        }
    }
}

// Medium Device Responsive
@media (min-width: 992px) and (max-width: 1199px) {
    .service-box {
        .icon-area {
            padding: 10px;
        }
    }
}

.service-text {
    position: relative;
  }
  
  .more-link {
    cursor: pointer;
    text-align: right;
    display: block;
    margin-top: 10px;
    color: #007bff;
  }
  
  .more-link:hover {
    text-decoration: underline;
  }

  .custom-modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1999999 !important;
    height: calc(100% - 1rem);
   
}

.myc {
    height: 360px !important;
    overflow: auto;
}

@media (max-width: 575px) {
    .custom-modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}
